












































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import FaucetLink from '@/components/misc/FaucetLink.vue'
import FungibleRow from '@/components/wallet/portfolio/FungibleRow.vue'
import DcommAsset from '@/js/DcommAsset'
import Erc20Token from '@/js/Erc20Token'
import ERC20Row from '@/components/wallet/portfolio/ERC20Row.vue'
import AddERC20TokenModal from '@/components/modals/AddERC20TokenModal.vue'
import TokenListModal from '@/components/modals/TokenList/TokenListModal.vue'
import { WalletType } from '@/js/wallets/types'

@Component({
    components: {
        TokenListModal,
        AddERC20TokenModal,
        ERC20Row,
        FaucetLink,
        FungibleRow,
    },
})
export default class Fungibles extends Vue {
    @Prop() search!: string

    $refs!: {
        add_token_modal: AddERC20TokenModal
        tokenlist_modal: TokenListModal
    }

    get networkStatus(): string {
        let stat = this.$store.state.Network.status
        return stat
    }

    addToken() {
        this.$refs.add_token_modal.open()
    }

    addTokenList() {
        this.$refs.tokenlist_modal.open()
    }

    get walletBalancesSorted(): DcommAsset[] {
        // let balance: DcommAsset[] = this.$store.getters['walletAssetsArray']
        let balance: DcommAsset[] = this.$store.getters['Assets/walletAssetsArray']

        // Sort by balance, then name
        balance.sort((a, b) => {
            let symbolA = a.symbol.toUpperCase()
            let symbolB = b.symbol.toUpperCase()
            let amtA = a.getAmount()
            let amtB = b.getAmount()
            let idA = a.id
            let idB = b.id

            // DCOMM always on top
            if (idA === this.dcmToken.id) {
                return -1
            } else if (idB === this.dcmToken.id) {
                return 1
            }

            if (amtA.gt(amtB)) {
                return -1
            } else if (amtA.lt(amtB)) {
                return 1
            }

            if (symbolA < symbolB) {
                return -1
            } else if (symbolA > symbolB) {
                return 1
            }
            return 0
        })

        return balance
    }

    get dcmToken(): DcommAsset {
        return this.$store.getters['Assets/AssetDCOMM']
    }

    get erc20Balances(): Erc20Token[] {
        let tokens: Erc20Token[] = this.$store.getters['Assets/networkErc20Tokens']
        let filt = tokens.filter((token) => {
            if (token.balanceBN.isZero()) return false
            return true
        })
        return filt
    }

    get walletBalances(): DcommAsset[] {
        let balance = this.walletBalancesSorted

        if (this.search) {
            balance = balance.filter((val) => {
                let query = this.search.toUpperCase()

                let nameUp = val.name.toUpperCase()
                let symbolUp = val.symbol.toUpperCase()

                if (nameUp.includes(query) || symbolUp.includes(query)) {
                    return true
                } else {
                    return false
                }
            })
        }

        return balance
    }
}
