import { Tx as DVMTx } from '@dcomm-tech/dcomm-js/dist/apis/dvm/tx'
import { astChain } from '@dcomm-tech/wallet-sdk'
import { bintools, actChain, athChain } from '@/DCOMM'
import { Tx as AuthorityTx } from '@dcomm-tech/dcomm-js/dist/apis/authorityvm/tx'
import { Tx as EVMTx } from '@dcomm-tech/dcomm-js/dist/apis/evm/tx'

export async function issueX(tx: DVMTx) {
    return astChain.issueTx('0x' + bintools.addChecksum(tx.toBuffer()).toString('hex'))
}

export async function issueP(tx: AuthorityTx) {
    return athChain.issueTx('0x' + bintools.addChecksum(tx.toBuffer()).toString('hex'))
}

export async function issueC(tx: EVMTx) {
    return actChain.issueTx('0x' + bintools.addChecksum(tx.toBuffer()).toString('hex'))
}
