










import { Vue, Component, Prop } from 'vue-property-decorator'
import { NFTTransferOutput, UTXO } from '@dcomm-tech/dcomm-js/dist/apis/dvm'
import NftPayloadView from '@/components/misc/NftPayloadView/NftPayloadView.vue'
import { PayloadBase } from '@dcomm-tech/dcomm-js/dist/utils'
import { Buffer } from '@dcomm-tech/dcomm-js'
import { PayloadTypes } from '@dcomm-tech/dcomm-js/dist/utils'
import Tooltip from '@/components/misc/Tooltip.vue'
import NFTViewModal from '@/components/modals/NFTViewModal.vue'
import NftCard from '@/components/wallet/portfolio/NftCard.vue'

let payloadtypes = PayloadTypes.getInstance()
@Component({
    components: { NftCard, NFTViewModal, Tooltip, NftPayloadView },
})
export default class CollectibleFamilyGroup extends Vue {
    @Prop() utxos!: UTXO[]
    $refs!: {
        modal: NFTViewModal
    }

    get quantity() {
        return this.utxos.length
    }

    get groupID() {
        let output = this.utxos[0].getOutput() as NFTTransferOutput
        return output.getGroupID()
    }

    get payload(): PayloadBase {
        let out = this.utxos[0].getOutput() as NFTTransferOutput
        let payload = out.getPayloadBuffer()

        let typeId = payloadtypes.getTypeID(payload)
        let pl: Buffer = payloadtypes.getContent(payload)
        let payloadbase: PayloadBase = payloadtypes.select(typeId, pl)

        return payloadbase
    }
}
