/*
The base wallet class used for common functionality
*/
import { BN } from '@dcomm-tech/dcomm-js'
import { UTXOSet as DVMUTXOSet } from '@dcomm-tech/dcomm-js/dist/apis/dvm'
import { UTXOSet as AuthorityUTXOSet } from '@dcomm-tech/dcomm-js/dist/apis/authorityvm'
import {
    ExportChainsC,
    ExportChainsP,
    ExportChainsX,
    UtxoHelper,
    TxHelper,
    GasHelper,
    chainIdFromAlias,
    astChain,
} from '@dcomm-tech/wallet-sdk'
import { dcomm, dvm, bintools, actChain, athChain } from '@/DCOMM'
import { UTXOSet as EVMUTXOSet } from '@dcomm-tech/dcomm-js/dist/apis/evm/utxos'
import { Tx as EVMTx, UnsignedTx as EVMUnsignedTx } from '@dcomm-tech/dcomm-js/dist/apis/evm/tx'
import {
    Tx as AuthorityTx,
    UnsignedTx as AuthorityUnsignedTx,
} from '@dcomm-tech/dcomm-js/dist/apis/authorityvm/tx'
import { Tx as DVMTx, UnsignedTx as DVMUnsignedTx } from '@dcomm-tech/dcomm-js/dist/apis/dvm/tx'
import { DvmImportChainType, WalletType } from '@/js/wallets/types'
import { issueC, issueP, issueX } from '@/helpers/issueTx'
const uniqid = require('uniqid')

abstract class WalletCore {
    id: string

    utxoset: DVMUTXOSet
    authorityUtxoset: AuthorityUTXOSet
    stakeAmount: BN

    isFetchUtxos: boolean
    isInit: boolean

    abstract getEvmAddressBech(): string
    abstract getEvmAddress(): string
    abstract getCurrentAddressDvm(): string
    abstract getChangeAddressDvm(): string
    abstract getCurrentAddressAuthority(): string
    abstract getAllAddressesP(): string[]
    abstract getAllAddressesX(): string[]

    abstract async signC(unsignedTx: EVMUnsignedTx): Promise<EVMTx>
    abstract async signX(unsignedTx: DVMUnsignedTx): Promise<DVMTx>
    abstract async signP(unsignedTx: AuthorityUnsignedTx): Promise<AuthorityTx>

    abstract async signMessage(msg: string, address?: string): Promise<string>
    abstract getAuthorityUTXOSet(): AuthorityUTXOSet

    getUTXOSet(): DVMUTXOSet {
        return this.utxoset
    }

    protected constructor() {
        this.id = uniqid()
        this.utxoset = new DVMUTXOSet()
        this.authorityUtxoset = new AuthorityUTXOSet()
        this.stakeAmount = new BN(0)

        this.isInit = false
        this.isFetchUtxos = false
    }

    async evmGetAtomicUTXOs(sourceChain: ExportChainsC) {
        const addrs = [this.getEvmAddressBech()]
        return await UtxoHelper.evmGetAtomicUTXOs(addrs, sourceChain)
    }

    async createImportTxC(sourceChain: ExportChainsC, utxoSet: EVMUTXOSet, fee: BN) {
        const bechAddr = this.getEvmAddressBech()
        const hexAddr = this.getEvmAddress()

        const toAddress = '0x' + hexAddr
        const ownerAddresses = [bechAddr]
        const fromAddresses = ownerAddresses
        const sourceChainId = chainIdFromAlias(sourceChain)

        return await actChain.buildImportTx(
            utxoSet,
            toAddress,
            ownerAddresses,
            sourceChainId,
            fromAddresses,
            fee
        )
    }

    /**
     *
     * @param sourceChain
     * @param fee Fee to use in nDCM
     * @param utxoSet
     */
    async importToACTChain(sourceChain: ExportChainsC, fee: BN, utxoSet?: EVMUTXOSet) {
        if (!utxoSet) {
            utxoSet = await this.evmGetAtomicUTXOs(sourceChain)
        }

        // TODO: Only use DCM utxos
        // TODO?: If the import fee for a utxo is greater than the value of the utxo, ignore it

        if (utxoSet.getAllUTXOs().length === 0) {
            throw new Error('Nothing to import.')
        }

        const unsignedTxFee = await this.createImportTxC(sourceChain, utxoSet, fee)
        const tx = await this.signC(unsignedTxFee)
        return this.issueC(tx)
    }

    protected async issueX(tx: DVMTx) {
        return issueX(tx)
    }

    protected async issueP(tx: AuthorityTx) {
        return issueP(tx)
    }

    protected async issueC(tx: EVMTx) {
        return issueC(tx)
    }

    async exportFromASTChain(amt: BN, destinationChain: ExportChainsX, importFee?: BN) {
        if (destinationChain === 'ACT' && !importFee)
            throw new Error('Exports to ACT chain must specify an import fee.')

        let amtFee = amt.clone()

        // Get destination address
        const destinationAddr =
            destinationChain === 'ATH'
                ? this.getCurrentAddressAuthority()
                : this.getEvmAddressBech()

        // Add import fee to transaction
        if (importFee) {
            amtFee = amt.add(importFee)
        } else if (destinationChain === 'ATH') {
            const fee = athChain.getTxFee()
            amtFee = amt.add(fee)
        }

        const fromAddresses = this.getAllAddressesX()
        const changeAddress = this.getChangeAddressDvm()
        const utxos = this.getUTXOSet()
        const exportTx = await TxHelper.buildDvmExportTransaction(
            destinationChain,
            utxos,
            fromAddresses,
            destinationAddr,
            amtFee,
            changeAddress
        )

        const tx = await this.signX(exportTx)

        return this.issueX(tx)
    }

    async exportFromATHChain(amt: BN, destinationChain: ExportChainsP, importFee?: BN) {
        const utxoSet = this.getAuthorityUTXOSet()

        const pChangeAddr = this.getCurrentAddressAuthority()
        const fromAddrs = this.getAllAddressesP()

        if (destinationChain === 'ACT' && !importFee)
            throw new Error('Exports to ACT chain must specify an import fee.')

        // Calculate ACT chain import fee
        let amtFee = amt.clone()
        if (importFee) {
            amtFee = amt.add(importFee)
        } else if (destinationChain === 'AST') {
            // We can add the import fee for AST chain
            const fee = dvm.getTxFee()
            amtFee = amt.add(fee)
        }

        // Get the destination address for the right chain
        const destinationAddr =
            destinationChain === 'ACT' ? this.getEvmAddressBech() : this.getCurrentAddressDvm()

        const exportTx = await TxHelper.buildAuthorityExportTransaction(
            utxoSet,
            fromAddrs,
            destinationAddr,
            amtFee,
            pChangeAddr,
            destinationChain
        )

        const tx = await this.signP(exportTx)
        return await this.issueP(tx)
    }

    /**
     *
     * @param amt The amount to receive on the destination chain, in nDCM.
     * @param destinationChain `AST` or `ATH`
     * @param fee Fee to use in the export transaction, given in nDCM.
     */
    async exportFromACTChain(amt: BN, destinationChain: ExportChainsC, exportFee: BN) {
        // Add import fee
        // AST and ATH have the same fee
        const importFee = dvm.getTxFee()
        const amtFee = amt.add(importFee)

        const hexAddr = this.getEvmAddress()
        const bechAddr = this.getEvmAddressBech()

        const fromAddresses = [hexAddr]

        const destinationAddr =
            destinationChain === 'AST'
                ? this.getCurrentAddressDvm()
                : this.getCurrentAddressAuthority()

        const exportTx = await TxHelper.buildEvmExportTransaction(
            fromAddresses,
            destinationAddr,
            amtFee,
            bechAddr,
            destinationChain,
            exportFee
        )

        const tx = await this.signC(exportTx)
        return this.issueC(tx)
    }

    /**
     * Returns the estimated gas to export from ACT chain.
     * @param destinationChain
     * @param amount
     */
    async estimateExportFee(destinationChain: ExportChainsC, amount: BN): Promise<number> {
        const hexAddr = this.getEvmAddress()
        const bechAddr = this.getEvmAddressBech()

        const destinationAddr =
            destinationChain === 'AST'
                ? this.getCurrentAddressDvm()
                : this.getCurrentAddressAuthority()

        return GasHelper.estimateExportGasFee(
            destinationChain,
            hexAddr,
            bechAddr,
            destinationAddr,
            amount
        )
    }

    async dvmGetAtomicUTXOs(sourceChain: ExportChainsX) {
        const addrs = this.getAllAddressesX()
        return await UtxoHelper.dvmGetAtomicUTXOs(addrs, sourceChain)
    }

    async authorityGetAtomicUTXOs(sourceChain: ExportChainsP) {
        const addrs = this.getAllAddressesP()
        return await UtxoHelper.authorityGetAtomicUTXOs(addrs, sourceChain)
    }

    async importToAuthorityChain(sourceChain: ExportChainsP): Promise<string> {
        const utxoSet = await this.authorityGetAtomicUTXOs(sourceChain)

        if (utxoSet.getAllUTXOs().length === 0) {
            throw new Error('Nothing to import.')
        }

        const sourceChainId = chainIdFromAlias(sourceChain)
        // Owner addresses, the addresses we exported to
        const pToAddr = this.getCurrentAddressAuthority()

        const hrp = dcomm.getHRP()
        const utxoAddrs = utxoSet
            .getAddresses()
            .map((addr) => bintools.addressToString(hrp, 'ATH', addr))

        const fromAddrs = utxoAddrs
        const ownerAddrs = utxoAddrs

        const unsignedTx = await athChain.buildImportTx(
            utxoSet,
            ownerAddrs,
            sourceChainId,
            [pToAddr],
            [pToAddr],
            [pToAddr],
            undefined,
            undefined
        )
        const tx = await this.signP(unsignedTx)
        // Pass in string because AJS fails to verify Tx type
        return this.issueP(tx)
    }

    async importToASTChain(sourceChain: DvmImportChainType) {
        const utxoSet = await this.dvmGetAtomicUTXOs(sourceChain)

        if (utxoSet.getAllUTXOs().length === 0) {
            throw new Error('Nothing to import.')
        }

        const xToAddr = this.getCurrentAddressDvm()

        const hrp = dcomm.getHRP()
        const utxoAddrs = utxoSet
            .getAddresses()
            .map((addr) => bintools.addressToString(hrp, 'AST', addr))

        const fromAddrs = utxoAddrs
        const ownerAddrs = utxoAddrs

        const sourceChainId = chainIdFromAlias(sourceChain)

        // Owner addresses, the addresses we exported to
        const unsignedTx = await dvm.buildImportTx(
            utxoSet,
            ownerAddrs,
            sourceChainId,
            [xToAddr],
            fromAddrs,
            [xToAddr]
        )

        const tx = await this.signX(unsignedTx)
        return this.issueX(tx)
    }
}
export { WalletCore }
