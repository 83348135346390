










import { Component, Vue } from 'vue-property-decorator'
import { UrlFormType, UtfFormType } from '@/components/wallet/studio/mint/types'
import { UTF8Payload } from '@dcomm-tech/dcomm-js/dist/utils'

@Component
export default class Utf8Form extends Vue {
    val = ''
    valBytes: any = 0
    bufferPayload: any = ''

    get isValid(): boolean {
        this.bufferPayload = new UTF8Payload(this.val)
        this.valBytes = this.bufferPayload.payload.length
        if (this.val.length === 0 || this.val.length > 1023) {
            return false
        }
        if (this.valBytes > 1023) {
            this.$store.dispatch('Notifications/add', {
                type: 'warning',
                title: 'Payload too large',
                message: 'UTF-8 payload should be less than 1023 Bytes',
            })
            return false
        }
        return true
    }

    onInput() {
        let msg: null | UtfFormType = null
        if (this.isValid) {
            msg = {
                text: this.val,
            }
        } else {
            msg = null
        }

        this.$emit('onInput', msg)
    }
}
