



































import 'reflect-metadata'
import { Vue, Component, Prop } from 'vue-property-decorator'

import DcommAsset from '../../../js/DcommAsset'
import Hexagon from '@/components/misc/Hexagon.vue'
import { BN } from '@dcomm-tech/dcomm-js'
import { bnToBig } from '../../../helpers/helper'
import { priceDict } from '../../../store/types'
import { WalletType } from '@/js/wallets/types'
import CopyText from '@/components/misc/CopyText.vue'
import Big from 'big.js'

@Component({
    components: {
        Hexagon,
        CopyText,
    },
})
export default class FungibleRow extends Vue {
    @Prop() asset!: DcommAsset

    get iconUrl(): string | null {
        if (!this.asset) return null

        if (this.isDcmToken) {
            return '/img/dcm_icon_circle.svg'
        }

        return null
    }

    get isBalance(): boolean {
        if (!this.asset) return false
        if (!this.amount.isZero()) {
            return true
        }
        return false
    }

    get totalUSD(): Big {
        if (!this.isDcmToken) return Big(0)
        let usdPrice = this.priceDict.usd
        let bigAmt = bnToBig(this.amount, this.asset.denomination)
        let usdBig = bigAmt.times(usdPrice)
        return usdBig
    }

    get priceDict(): priceDict {
        return this.$store.state.prices
    }

    get sendLink(): string {
        if (!this.asset) return `/wallet/transfer`
        return `/wallet/transfer?asset=${this.asset.id}&chain=AST`
    }

    get dcmToken(): DcommAsset {
        return this.$store.getters['Assets/AssetDCOMM']
    }

    get isDcmToken(): boolean {
        if (!this.asset) return false

        if (this.dcmToken.id === this.asset.id) {
            return true
        } else {
            return false
        }
    }

    get name(): string {
        let name = this.asset.name
        // TODO: Remove this hack after network change
        if (name === 'DCOMM') return 'DCM'
        return name
    }

    get symbol(): string {
        let sym = this.asset.symbol

        // TODO: Remove this hack after network change
        if (sym === 'DCOMM') return 'DCM'
        return sym
    }

    get amount() {
        let amt = this.asset.getTotalAmount()
        return amt.add(this.evmDcmBalance)
    }

    get amtBig() {
        return bnToBig(this.amount, this.asset.denomination)
    }

    get evmDcmBalance(): BN {
        let wallet: WalletType | null = this.$store.state.activeWallet

        if (!this.isDcmToken || !wallet) {
            return new BN(0)
        }
        // Convert to 9 decimal places
        let bal = wallet.ethBalance
        let balRnd = bal.divRound(new BN(Math.pow(10, 9).toString()))
        return balRnd
    }
}
