import { dcomm, dvm, bintools, actChain, athChain } from '@/DCOMM'
import { ITransaction } from '@/components/wallet/transfer/types'
import { digestMessage } from '@/helpers/helper'
import { WalletNameType } from '@/js/wallets/types'

import { Buffer as BufferDcomm, BN } from '@dcomm-tech/dcomm-js'
import {
    KeyPair as DVMKeyPair,
    KeyChain as DVMKeyChain,
    UTXOSet as DVMUTXOSet,
    UTXO,
    UnsignedTx,
} from '@dcomm-tech/dcomm-js/dist/apis/dvm'
import {
    KeyPair as AuthorityKeyPair,
    KeyChain as AuthorityKeyChain,
    UTXOSet as AuthorityUTXOSet,
    UTXOSet,
} from '@dcomm-tech/dcomm-js/dist/apis/authorityvm'
import {
    KeyChain,
    KeyChain as EVMKeyChain,
    UTXOSet as EVMUTXOSet,
} from '@dcomm-tech/dcomm-js/dist/apis/evm'
import { PayloadBase } from '@dcomm-tech/dcomm-js/dist/utils'
import { buildUnsignedTransaction } from '../TxHelper'
import { DcommWalletCore, UnsafeWallet } from './types'
import { UTXO as AuthorityUTXO } from '@dcomm-tech/dcomm-js/dist/apis/authorityvm/utxos'
import { privateToAddress } from 'ethereumjs-util'
import { Tx as DVMTx, UnsignedTx as DVMUnsignedTx } from '@dcomm-tech/dcomm-js/dist/apis/dvm/tx'
import {
    Tx as AuthorityTx,
    UnsignedTx as AuthorityUnsignedTx,
} from '@dcomm-tech/dcomm-js/dist/apis/authorityvm/tx'
import { Tx as EvmTx, UnsignedTx as EVMUnsignedTx } from '@dcomm-tech/dcomm-js/dist/apis/evm/tx'
import Erc20Token from '@/js/Erc20Token'
import { WalletCore } from '@/js/wallets/WalletCore'
import { WalletHelper } from '@/helpers/wallet_helper'
import { dvmGetAllUTXOs, authorityGetAllUTXOs } from '@/helpers/utxo_helper'
import { UTXO as DVMUTXO } from '@dcomm-tech/dcomm-js/dist/apis/dvm/utxos'
import { Transaction } from '@ethereumjs/tx'
import { ExportChainsC, ExportChainsP, ExportChainsX } from '@dcomm-tech/wallet-sdk'

class SingletonWallet extends WalletCore implements DcommWalletCore, UnsafeWallet {
    keyChain: DVMKeyChain
    keyPair: DVMKeyPair

    authorityKeyChain: AuthorityKeyChain
    authorityKeyPair: AuthorityKeyPair

    chainId: string
    chainIdP: string

    key: string

    stakeAmount: BN

    type: WalletNameType

    ethKey: string
    ethKeyBech: string
    ethKeyChain: EVMKeyChain
    ethAddress: string
    ethAddressBech: string
    ethBalance: BN

    constructor(pk: string) {
        super()

        this.key = pk

        this.chainId = dvm.getBlockchainAlias() || dvm.getBlockchainID()
        this.chainIdP = athChain.getBlockchainAlias() || athChain.getBlockchainID()

        const hrp = dcomm.getHRP()

        this.keyChain = new DVMKeyChain(hrp, this.chainId)
        this.keyPair = this.keyChain.importKey(pk)

        this.authorityKeyChain = new AuthorityKeyChain(hrp, this.chainIdP)
        this.authorityKeyPair = this.authorityKeyChain.importKey(pk)

        this.stakeAmount = new BN(0)

        // Derive EVM key and address
        const pkBuf = bintools.cb58Decode(pk.split('-')[1])
        const pkHex = pkBuf.toString('hex')
        const pkBuffNative = Buffer.from(pkHex, 'hex')

        this.ethKey = pkHex
        this.ethAddress = privateToAddress(pkBuffNative).toString('hex')
        this.ethBalance = new BN(0)

        const cPrivKey = `PrivateKey-` + bintools.cb58Encode(BufferDcomm.from(pkBuf))
        this.ethKeyBech = cPrivKey
        const cKeyChain = new KeyChain(dcomm.getHRP(), 'ACT')
        this.ethKeyChain = cKeyChain

        const cKeypair = cKeyChain.importKey(cPrivKey)
        this.ethAddressBech = cKeypair.getAddressString()

        this.type = 'singleton'
        this.isInit = true
    }

    getChangeAddressDvm(): string {
        return this.getCurrentAddressDvm()
    }

    getCurrentAddressDvm(): string {
        return this.keyPair.getAddressString()
    }

    getChangeAddressAuthority(): string {
        return this.getCurrentAddressAuthority()
    }

    getDerivedAddresses(): string[] {
        const addr = this.getCurrentAddressDvm()
        return [addr]
    }

    getDerivedAddressesP() {
        return [this.getCurrentAddressAuthority()]
    }

    getAllDerivedExternalAddresses(): string[] {
        return this.getDerivedAddresses()
    }

    getExtendedAuthorityAddresses(): string[] {
        const addr = this.authorityKeyPair.getAddressString()
        return [addr]
    }

    getHistoryAddresses(): string[] {
        const addr = this.getCurrentAddressDvm()
        return [addr]
    }

    getAuthorityRewardAddress(): string {
        return this.getCurrentAddressAuthority()
    }

    getCurrentAddressAuthority(): string {
        return this.authorityKeyPair.getAddressString()
    }

    getBaseAddress(): string {
        return this.getCurrentAddressDvm()
    }

    async getStake(): Promise<BN> {
        this.stakeAmount = await WalletHelper.getStake(this)
        return this.stakeAmount
    }

    getAuthorityUTXOSet(): AuthorityUTXOSet {
        return this.authorityUtxoset
    }

    getEvmAddress(): string {
        return this.ethAddress
    }

    getEvmAddressBech(): string {
        return this.ethAddressBech
    }

    async getEthBalance() {
        const bal = await WalletHelper.getEthBalance(this)
        this.ethBalance = bal
        return bal
    }

    async updateUTXOsX(): Promise<DVMUTXOSet> {
        const result = await dvmGetAllUTXOs([this.getCurrentAddressDvm()])
        this.utxoset = result
        return result
    }

    async updateUTXOsP(): Promise<AuthorityUTXOSet> {
        const result = await authorityGetAllUTXOs([this.getCurrentAddressAuthority()])
        this.authorityUtxoset = result
        return result
    }

    async getUTXOs(): Promise<void> {
        this.isFetchUtxos = true

        await this.updateUTXOsX()
        await this.updateUTXOsP()

        await this.getStake()
        await this.getEthBalance()

        this.isFetchUtxos = false

        return
    }

    async buildUnsignedTransaction(
        orders: (ITransaction | UTXO)[],
        addr: string,
        memo?: BufferDcomm
    ) {
        const changeAddress = this.getChangeAddressDvm()
        const derivedAddresses = this.getDerivedAddresses()
        const utxoset = this.getUTXOSet() as DVMUTXOSet

        return buildUnsignedTransaction(
            orders,
            addr,
            derivedAddresses,
            utxoset,
            changeAddress,
            memo
        )
    }

    async issueBatchTx(
        orders: (ITransaction | DVMUTXO)[],
        addr: string,
        memo: BufferDcomm | undefined
    ): Promise<string> {
        return await WalletHelper.issueBatchTx(this, orders, addr, memo)
    }

    getFirstAvailableAddressAuthority(): string {
        return this.getCurrentAddressAuthority()
    }

    onnetworkchange(): void {
        const hrp = dcomm.getHRP()

        this.keyChain = new DVMKeyChain(hrp, this.chainId)
        this.utxoset = new DVMUTXOSet()
        this.keyPair = this.keyChain.importKey(this.key)

        this.authorityKeyChain = new AuthorityKeyChain(hrp, this.chainIdP)
        this.authorityUtxoset = new AuthorityUTXOSet()
        this.authorityKeyPair = this.authorityKeyChain.importKey(this.key)

        // Update EVM values
        this.ethKeyChain = new EVMKeyChain(dcomm.getHRP(), 'ACT')
        const cKeypair = this.ethKeyChain.importKey(this.ethKeyBech)
        this.ethAddressBech = cKeypair.getAddressString()
        this.ethBalance = new BN(0)

        this.getUTXOs()
    }

    async signX(unsignedTx: DVMUnsignedTx): Promise<DVMTx> {
        const keychain = this.keyChain

        const tx = unsignedTx.sign(keychain)
        return tx
    }

    async signP(unsignedTx: AuthorityUnsignedTx): Promise<AuthorityTx> {
        const keychain = this.authorityKeyChain
        const tx = unsignedTx.sign(keychain)
        return tx
    }

    async signC(unsignedTx: EVMUnsignedTx): Promise<EvmTx> {
        const keyChain = this.ethKeyChain
        return unsignedTx.sign(keyChain)
    }

    async signEvm(tx: Transaction) {
        const keyBuff = Buffer.from(this.ethKey, 'hex')
        return tx.sign(keyBuff)
    }

    async signMessage(msgStr: string): Promise<string> {
        const digest = digestMessage(msgStr)

        const digestHex = digest.toString('hex')
        const digestBuff = BufferDcomm.from(digestHex, 'hex')
        const signed = this.keyPair.sign(digestBuff)

        return bintools.cb58Encode(signed)
    }

    async delegate(
        nodeID: string,
        amt: BN,
        start: Date,
        end: Date,
        rewardAddress?: string,
        utxos?: AuthorityUTXO[]
    ): Promise<string> {
        return await WalletHelper.delegate(this, nodeID, amt, start, end, rewardAddress, utxos)
    }

    async validate(
        nodeID: string,
        amt: BN,
        start: Date,
        end: Date,
        delegationFee: number = 0,
        rewardAddress?: string,
        utxos?: AuthorityUTXO[]
    ): Promise<string> {
        return await WalletHelper.validate(
            this,
            nodeID,
            amt,
            start,
            end,
            delegationFee,
            rewardAddress,
            utxos
        )
    }

    async createNftFamily(name: string, symbol: string, groupNum: number) {
        return await WalletHelper.createNftFamily(this, name, symbol, groupNum)
    }

    async mintNft(mintUtxo: DVMUTXO, payload: PayloadBase, quantity: number) {
        return await WalletHelper.mintNft(this, mintUtxo, payload, quantity)
    }

    async sendEth(to: string, amount: BN, gasPrice: BN, gasLimit: number) {
        return await WalletHelper.sendEth(this, to, amount, gasPrice, gasLimit)
    }

    async estimateGas(to: string, amount: BN, token: Erc20Token): Promise<number> {
        return await WalletHelper.estimateGas(this, to, amount, token)
    }

    async sendERC20(
        to: string,
        amount: BN,
        gasPrice: BN,
        gasLimit: number,
        token: Erc20Token
    ): Promise<string> {
        return await WalletHelper.sendErc20(this, to, amount, gasPrice, gasLimit, token)
    }

    getAllAddressesX() {
        return [this.getCurrentAddressDvm()]
    }

    getAllAddressesP() {
        return [this.getCurrentAddressAuthority()]
    }
}

export { SingletonWallet }
