





























































































































import { Vue, Component } from 'vue-property-decorator'
import DcmInput from '@/components/misc/DcmInput.vue'
import { priceDict } from '@/store/types'
import { WalletType } from '@/js/wallets/types'
import { GasHelper, TxHelper, bnToBigDcmX, bnToBigDcmC, bnToDcmC } from '@dcomm-tech/wallet-sdk'

// @ts-ignore
import { QrInput } from '@dcomm-tech/vue-components'
import Big from 'big.js'
import { BN } from '@dcomm-tech/dcomm-js'
import { bnToBig } from '@/helpers/helper'
import { web3 } from '@/evm'
import EVMInputDropdown from '@/components/misc/EVMInputDropdown/EVMInputDropdown.vue'
import Erc20Token from '@/js/Erc20Token'
import { iErc721SelectInput } from '@/components/misc/EVMInputDropdown/types'
import { WalletHelper } from '@/helpers/wallet_helper'

@Component({
    components: {
        EVMInputDropdown,
        DcmInput,
        QrInput,
    },
})
export default class FormC extends Vue {
    isConfirm = false
    isSuccess = false
    addressIn = ''
    amountIn = new BN(0)
    gasPrice = new BN(225000000000)
    gasPriceInterval: ReturnType<typeof setTimeout> | undefined = undefined
    gasLimit = 21000
    err = ''
    isLoading = false

    formAddress = ''
    formAmount = new BN(0)
    formToken: Erc20Token | 'native' = 'native'
    canSendAgain = false

    isCollectible = false
    formCollectible: iErc721SelectInput | null = null

    txHash = ''

    $refs!: {
        token_in: EVMInputDropdown
    }

    created() {
        // Update gas price automatically
        this.updateGasPrice()
        this.gasPriceInterval = setInterval(() => {
            if (!this.isConfirm) {
                this.updateGasPrice()
            }
        }, 15000)
    }

    destroyed() {
        if (this.gasPriceInterval) {
            clearInterval(this.gasPriceInterval)
        }
    }

    get gasPriceNumber() {
        return bnToBigDcmX(this.gasPrice).toFixed(0)
    }

    async updateGasPrice() {
        this.gasPrice = await GasHelper.getAdjustedGasPrice()
    }

    onAmountChange(val: BN) {
        this.amountIn = val
    }

    onTokenChange(token: Erc20Token | 'native') {
        this.formToken = token
        this.isCollectible = false
    }

    onCollectibleChange(val: iErc721SelectInput) {
        this.isCollectible = true
        this.formCollectible = val
    }

    get wallet(): WalletType | null {
        return this.$store.state.activeWallet
    }

    get priceDict(): priceDict {
        return this.$store.state.prices
    }

    get denomination(): number {
        if (this.formToken === 'native') {
            return 9
        } else {
            return parseInt(this.formToken.data.decimals as string)
        }
    }

    get symbol(): string {
        if (this.formToken === 'native') return 'DCM'
        return this.formToken.data.symbol
    }

    get totalUSD(): Big | null {
        if (this.formToken !== 'native') {
            return null
        }

        let bigAmt = bnToBig(this.amountIn, 18)
        let usdPrice = this.priceDict.usd
        let bigFee = bnToBig(this.maxFee, 18)
        let usdBig = bigAmt.add(bigFee).times(usdPrice)
        return usdBig
    }

    validateAddress(addr: string) {
        if (addr.substring(0, 4) !== 'ACT-0x' && addr.substring(0, 2) !== '0x') {
            return false
        }

        return true
    }

    validate(): boolean {
        this.err = ''

        let addr = this.addressIn

        if (!this.validateAddress(addr)) {
            this.err =
                'Invalid ACT Chain address. Make sure your address begins with "0x" or "ACT-0x"'
            return false
        }

        if (addr.substring(0, 2) === 'ACT-') {
            let hexStr = addr.substring(2)
            if (!web3.utils.isAddress(hexStr)) {
                this.err = 'Not a valid ACT chain address.'
                return false
            }
        } else {
            if (!web3.utils.isAddress(addr)) {
                this.err = 'Not a valid ACT chain address.'
                return false
            }
        }

        return true
    }

    get maxFee(): BN {
        let res = this.gasPrice.mul(new BN(this.gasLimit))
        return res
    }

    get maxFeeUSD() {
        return bnToBigDcmC(this.maxFee).times(this.priceDict.usd)
    }

    get maxFeeText(): string {
        return bnToDcmC(this.maxFee)
    }

    // balance - (gas * price)
    // get maxAmt() {
    //     // let priceWei = new BN(this.gasPrice).mul(new BN(Math.pow(10, 9)))
    //     // let res = priceWei.mul(new BN(this.gasLimit))
    //     let res = this.rawBalance.sub(this.maxFee)
    //     return res.divRound(new BN(Math.pow(10, 9)))
    // }

    async estimateGas() {
        if (!this.wallet) return

        if (!this.isCollectible) {
            if (this.formToken === 'native') {
                // For DCM Transfers
                let gasLimit = await TxHelper.estimateDcmGas(
                    this.wallet.getEvmAddress(),
                    this.formAddress,
                    this.formAmount,
                    this.gasPrice
                )
                this.gasLimit = gasLimit
            } else {
                // For ERC20 tokens
                let tx = (this.formToken as Erc20Token).createTransferTx(
                    this.formAddress,
                    this.formAmount
                )
                let estGas = await WalletHelper.estimateTxGas(this.wallet, tx)
                this.gasLimit = estGas
            }
        }

        // For erc721 transfers
        if (this.isCollectible && this.formCollectible) {
            let fromAddr = '0x' + this.wallet.getEvmAddress()
            let toAddr = this.formAddress
            let tx = this.formCollectible.token.createTransferTx(
                fromAddr,
                toAddr,
                this.formCollectible.id
            )
            let estGas = await WalletHelper.estimateTxGas(this.wallet, tx)
            this.gasLimit = estGas
        }
    }

    confirm() {
        if (!this.wallet) return
        if (!this.validate()) return
        this.formAddress = this.addressIn
        this.formAmount = this.amountIn.clone()
        this.isConfirm = true

        this.estimateGas()
    }

    get formAmountBig() {
        return bnToBig(this.formAmount, this.denomination)
    }

    cancel() {
        this.err = ''
        this.isConfirm = false
    }

    startAgain() {
        this.isConfirm = false
        this.isSuccess = false
        this.err = ''

        this.$refs.token_in.clear()

        this.amountIn = new BN(0)
        this.gasLimit = 21000
        this.addressIn = ''
    }

    activated() {
        this.startAgain()

        let tokenAddr = this.$route.query.token
        let tokenId = this.$route.query.tokenId

        if (tokenAddr) {
            if (tokenAddr === 'native') {
                this.$refs.token_in.setToken(tokenAddr)
            } else {
                let token = this.$store.getters['Assets/findErc20'](tokenAddr)
                let erc721 = this.$store.getters['Assets/ERC721/find'](tokenAddr)
                if (token) {
                    this.$refs.token_in.setToken(token)
                } else if (erc721 && tokenId) {
                    this.$refs.token_in.setErc721Token(erc721, tokenId as string)
                }
            }
        }
    }

    get canConfirm() {
        if (!this.isCollectible) {
            if (this.amountIn.isZero()) return false
            if (this.gasLimit <= 0 && this.formToken == 'native') return false
        }

        // if (this.gasPrice <= 0) return false
        if (this.addressIn.length < 6) return false

        return true
    }

    async submit() {
        if (!this.wallet) return
        this.isLoading = true
        // convert base 9 to 18

        let gasPriceWei = this.gasPrice
        let toAddress = this.formAddress

        if (toAddress.substring(0, 2) === 'ACT-') {
            toAddress = toAddress.substring(2)
        }

        try {
            if (!this.isCollectible) {
                if (this.formToken === 'native') {
                    let formAmt = this.formAmount

                    let txHash = await this.wallet.sendEth(
                        toAddress,
                        formAmt,
                        gasPriceWei,
                        this.gasLimit
                    )
                    this.onSuccess(txHash)
                } else {
                    let txHash = await this.wallet.sendERC20(
                        toAddress,
                        this.formAmount,
                        gasPriceWei,
                        this.gasLimit,
                        this.formToken
                    )
                    this.onSuccess(txHash)
                }
            } else {
                if (!this.formCollectible) throw 'No collectible selected.'
                let txHash = await WalletHelper.sendErc721(
                    this.wallet,
                    toAddress,
                    gasPriceWei,
                    this.gasLimit,
                    this.formCollectible.token,
                    this.formCollectible.id
                )
                this.onSuccess(txHash)
            }
        } catch (e) {
            this.onError(e)
        }
    }

    onSuccess(txId: string) {
        this.isLoading = false
        this.isSuccess = true
        this.txHash = txId

        this.$store.dispatch('Notifications/add', {
            title: this.$t('transfer.success_title'),
            message: this.$t('transfer.success_msg'),
            type: 'success',
        })

        // Refresh UTXOs
        this.canSendAgain = false
        setTimeout(() => {
            this.$store.dispatch('Assets/updateUTXOs')
            this.$store.dispatch('History/updateTransactionHistory')
            this.$store.dispatch('History/updateEvmTransaction')
            this.canSendAgain = true
        }, 3000)
    }

    onError(err: any) {
        this.err = err
        this.isLoading = false

        console.error(err)

        this.$store.dispatch('Notifications/add', {
            title: this.$t('transfer.error_title'),
            message: this.$t('transfer.error_msg'),
            type: 'error',
        })
    }
}
