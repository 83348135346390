





























import 'reflect-metadata'
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'

const uuidv1 = require('uuid/v1')

import { BN } from '@dcomm-tech/dcomm-js'
import CurrencyInputDropdown from '@/components/misc/CurrencyInputDropdown.vue'
import DcommAsset from '@/js/DcommAsset'
import { AssetsDict } from '@/store/modules/assets/types'
import { ICurrencyInputDropdownValue, ITransaction } from '@/components/wallet/transfer/types'

@Component({
    components: {
        CurrencyInputDropdown,
    },
})
export default class TxList extends Vue {
    tx_list: ITransaction[] = []
    disabledAssets: DcommAsset[][] = []
    next_initial: DcommAsset | null = null

    @Prop({ default: false }) disabled!: boolean

    deactivated() {
        this.reset()
    }

    updateUnavailable(): void {
        let res: DcommAsset[][] = []
        let allDisabled = []

        for (var i = 0; i < this.tx_list.length; i++) {
            let localDisabled: DcommAsset[] = []

            allDisabled.push(this.tx_list[i].asset)
            for (var n = 0; n < this.tx_list.length; n++) {
                if (i === n) continue
                let assetNow = this.tx_list[n].asset
                localDisabled.push(assetNow)
            }
            res.push(localDisabled)
        }

        this.next_initial = null
        for (i = 0; i < this.assets_list.length; i++) {
            let asset = this.assets_list[i]
            if (!allDisabled.includes(asset)) {
                this.next_initial = asset
                break
            }
        }

        this.disabledAssets = res
    }

    oninputchange(index: number, event: ICurrencyInputDropdownValue): void {
        let asset = event.asset
        let amt = event.amount

        if (!asset) return

        this.tx_list[index].asset = asset
        this.tx_list[index].amount = amt

        this.updateUnavailable()

        this.$emit('change', this.tx_list)
    }

    removeTx(index: number): void {
        this.tx_list.splice(index, 1)
        this.updateUnavailable()
        this.$emit('change', this.tx_list)
    }

    addTx(id?: string): void {
        if (this.tx_list.length >= this.assets_list.length) {
            return
        }

        let uuid = uuidv1()

        if (id) {
            this.tx_list.push({
                uuid: uuid,
                asset: this.assets[id],
                amount: new BN(0),
            })
        } else if (this.next_initial) {
            this.tx_list.push({
                uuid: uuid,
                asset: this.next_initial,
                amount: new BN(0),
            })
        }
        this.$emit('change', this.tx_list)
    }

    // clears the list
    clear(): void {
        for (var i = this.tx_list.length - 1; i >= 0; i--) {
            this.removeTx(i)
        }
    }

    addDefaultAsset() {
        this.next_initial = this.assets_list[0]
        if (this.$route.query.asset) {
            let assetId = this.$route.query.asset as string
            this.addTx(assetId)
        } else {
            this.addTx()
        }
    }

    // clear and add the default asset
    reset() {
        this.clear()
        this.addDefaultAsset()
    }

    activated() {
        this.reset()
    }

    @Watch('assets_list')
    onAssetListChange() {
        this.updateUnavailable()
    }

    get assets_list(): DcommAsset[] {
        // return this.$store.getters.walletAssetsArray
        return this.$store.getters['Assets/walletAssetsArray']
    }
    get assets(): AssetsDict {
        // return this.$store.getters.walletAssetsDict
        return this.$store.getters['Assets/walletAssetsDict']
    }
    get showAdd(): boolean {
        if (this.disabled) return false
        if (this.tx_list.length === this.assets_list.length || this.assets_list.length === 0) {
            return false
        }
        return true
    }
}
